<template>
  <div class="">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn  btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>

      <div class="col-auto">
        <button
          @click="$router.push({ name: 'items-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un nouveau produit
        </button>
      </div>

      <div class="col-auto">
        <button @click="printAll()" class="btn btn-secondary">
          <i class="bi bi-printer"></i>
          Imprimer
        </button>
      </div>
    </div>
    <br />

    <vue-data-table 
      :headers="headers" 
      :data="items" 
      :items-per-page="10" 
      :buttons="buttons"
      :summable-columns="summableColumns"
    />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Catégorie</th>
          <th scope="col">Unité</th>
          <th scope="col">Prix TTC</th>
          <th scope="col">TVA</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            {{ item.reference }}
          </td>
          <td>
            {{ item.fullName }}
          </td>

          <td>
            <span v-if="item.section">
              {{ item.section.fullName }}
            </span>
          </td>

          <td>
            {{ item.measuring }}
          </td>

          <td>
            {{ parseFloat(item.priceTTC).toFixed(2) }}
          </td>

          <td>{{ item.tva }} %</td>

          <td class="d-flex">
            <button
              v-if="$userRole == 'Admin'"
              @click="
                $router.push({
                  name: 'items-edit',
                  params: {
                    reference: item.reference,
                  },
                })
              "
              class="btn"
            >
              <i class="bi bi-pencil-square"></i>
            </button>

            <button
              
              @click="
                $router.push({
                  name: 'items-details',
                  params: {
                    reference: item.reference,
                  },
                })
              "
              class="btn"
            >
              <i class="bi bi-list-ul text-primary"></i>
            </button>
            <button v-if="$userRole == 'Admin'" @click="remove(item)" class="btn">
              <i class="bi bi-trash text-danger"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
      headers: [
        { field: 'reference', label: 'Référence',class:'' },
        { field: 'fullName', label: 'Désignation',class:'' },
        { field: 'fullName', label: 'Catégorie',class:'' },
        { field: 'measuring', label: 'Unité',class:'' },
        { field: 'priceTTC', label: 'Prix TTC',class:'' },
        { field: 'tva', label: 'TVA',class:'' },
      ],
      buttons: [
        {
          label: 'Edit',
          icon: 'bi bi-pencil-square',
          class: 'btn',
          visible: () => this.$userRole === 'Admin',
          action: (item) => {
            this.$router.push({
              name: 'items-edit',
              params: { reference: item.reference },
            });
          },
        },
        {
          label: 'Details',
          icon: 'bi bi-list-ul text-primary',
          class: 'btn',
          action: (item) => {
            this.$router.push({
              name: 'items-details',
              params: { reference: item.reference },
            });
          },
        },
        {
          label: 'Delete',
          icon: 'bi bi-trash text-danger',
          class: 'btn',
          visible: () => this.$userRole === 'Admin',
          action: (item) => {
            this.remove(item);
          },
        }
      ],
      summableColumns: [
        
      ],
    };
  },
  computed: {
    ...mapGetters("item", {
      items: "getItems",
    }),
    // imageUrl() {
    //   return (item) => `${item.image}`
    // },
  },
  mounted() {
    this.$store.dispatch("item/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("item/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("item/getAll");
    },

    async sort(value) {
      return await this.$store.commit("item/sort", value);
    },

    async remove(data) {
      let item = data;
      await this.$confirm({
        message: "Vous voulez supprimer l'article  " + item.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("item/destroy", item.reference);
          }
        },
      });
    },

    async printAll() {
      return await this.$store.dispatch("item/printAll");
    },
  },
};
</script>
